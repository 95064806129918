import * as React from "react";
import axios from "axios";
import AuthToken from "../../helpers/AuthToken";
import { Typography } from "@mui/material";
import { withSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Layout from "../Layout";
import { navigate } from "gatsby";
import { getUser, isBrowser, signOut } from "../../helpers/LocalStorage";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: ``,
      lastName: ``,
      email: ``,
    };
  }

  componentDidMount() {
    AuthToken();
    const user = getUser();
    axios
      .get(`${process.env.GATSBY_API_URL}/users/${user.id}`)
      .then((response) => {
        this.setState({
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
            if (isBrowser()) {
              navigate("/");
            }
          }
        } else {
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
          if (isBrowser()) {
            navigate("/");
          }
        }
      });
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    AuthToken();
    axios
      .put(`${process.env.GATSBY_API_URL}/profile`, this.state)
      .then(() => {
        this.props.enqueueSnackbar("Profile updated", {
          variant: "success",
        });
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          signOut();
        } else {
          this.props.enqueueSnackbar(err.response.data.reason, {
            variant: "error",
          });
        }
      });
  };

  render() {
    return (
      <Layout>
        <Paper elevation={1} sx={{ p: 2 }}>
          <Typography align="center" component="h1" variant="h4">
            My Profile
          </Typography>
          <Box sx={{ m: 2 }} />
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  type="email"
                  label="Email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <Button type="submit" variant="contained" color="primary">
                  Update Profile
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Layout>
    );
  }
}

export default withSnackbar(Profile);
