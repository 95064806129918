import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { withSnackbar, useSnackbar } from "notistack";
import Layout from "../Layout";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { signOut } from "../../helpers/LocalStorage";
import AuthToken from "../../helpers/AuthToken";

function ChildrenIndexSettings() {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleCellEditCommit = React.useCallback(
    async (params) => {
      AuthToken();
      const data = { [params.field]: params.value };
      axios
        .patch(`${process.env.GATSBY_API_URL}/index-settings/children/${params.id}`, data)
        .then(() => {
          enqueueSnackbar("Children index setting updated", { variant: "success" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(err.response.data.reason, { variant: "error" });
          setRows((prev) => [...prev]);
        });
    },
    [enqueueSnackbar],
  );

  const columns = React.useMemo(
    () => [
      { field: "index", width: 200, headerName: "Index" },
      { field: "value", width: 100, editable: true, type: "number", headerName: "Boost" },
    ],
    [],
  );

  React.useEffect(() => {
    setIsLoading(true);
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/index-settings/children`)
      .then((response) => {
        setRows(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Unable to connect to search API", { variant: "error" });
        }
      });
  }, [enqueueSnackbar]);

  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Typography component="h1" variant="h4">
          Children Index Settings
        </Typography>
        <Box sx={{ m: 1 }} />
        <div style={{ height: 575, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            loading={isLoading}
            disableColumnMenu
            components={{
              Toolbar: GridToolbar,
            }}
            onCellEditCommit={handleCellEditCommit}
          />
        </div>
      </Paper>
    </Layout>
  );
}

export default withSnackbar(ChildrenIndexSettings);
