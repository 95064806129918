import React from "react";
import { navigate } from "gatsby";
import { isSignedIn } from "../../services/auth";
import { isBrowser } from "../../helpers/LocalStorage";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isSignedIn() && location.pathname !== `/app/sigin`) {
    if (isBrowser()) {
      navigate("/app/signin");
    }
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
