import * as React from "react";
import { withSnackbar } from "notistack";
import Grid from "@mui/material/Grid";
import DashboardCard from "./reports/DashboardCard";
import Layout from "../Layout";
import axios from "axios";
import AuthToken from "../../helpers/AuthToken";
import { signOut } from "../../helpers/LocalStorage";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalAdultClinicalTrials: 0,
      totalAdultEvents: 0,
      totalAdultLocations: 0,
      totalAdultPages: 0,
      totalAdultPosts: 0,
      totalAdultProviders: 0,
      totalAdultServices: 0,
      totalChildrenClinicalTrials: 0,
      totalChildrenEvents: 0,
      totalChildrenLocations: 0,
      totalChildrenPages: 0,
      totalChildrenProviders: 0,
      totalChildrenPosts: 0,
      totalChildrentServices: 0,
      totalChildrenArticles: 0,
      totalUsers: 0,
    };
  }

  componentDidMount() {
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}`)
      .then((response) => {
        this.setState({
          totalAdultClinicalTrials: response.data.totalAdultClinicalTrials,
          totalAdultEvents: response.data.totalAdultEvents,
          totalAdultLocations: response.data.totalAdultLocations,
          totalAdultPages: response.data.totalAdultPages,
          totalAdultPosts: response.data.totalAdultPosts,
          totalAdultProviders: response.data.totalAdultProviders,
          totalAdultServices: response.data.totalAdultServices,
          totalChildrenClinicalTrials: response.data.totalChildrenClinicalTrials,
          totalChildrenEvents: response.data.totalChildrenEvents,
          totalChildrenLocations: response.data.totalChildrenLocations,
          totalChildrenPages: response.data.totalChildrenPages,
          totalChildrenPosts: response.data.totalChildrenPosts,
          totalChildrenProviders: response.data.totalChildrenProviders,
          totalChildrenServices: response.data.totalChildrenServices,
          totalChildrenArticles: response.data.totalChildrenArticles,
          totalUsers: response.data.totalUsers,
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }

  render() {
    return (
      <Layout>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <DashboardCard title="Adult Clinical Trials" metric={this.state.totalAdultClinicalTrials} />
          </Grid>
          <Grid item xs={6}>
            <DashboardCard title="Children Clinical Trials" metric={this.state.totalChildrenClinicalTrials} />
          </Grid>
          <Grid item xs={6}>
            <DashboardCard title="Adult Events" metric={this.state.totalAdultEvents} />
          </Grid>
          <Grid item xs={6}>
            <DashboardCard title="Children Events" metric={this.state.totalChildrenEvents} />
          </Grid>
          <Grid item xs={6}>
            <DashboardCard title="Adult Locations" metric={this.state.totalAdultLocations} />
          </Grid>
          <Grid item xs={6}>
            <DashboardCard title="Children Locations" metric={this.state.totalChildrenLocations} />
          </Grid>
          <Grid item xs={6}>
            <DashboardCard title="Adult Pages" metric={this.state.totalAdultPages} />
          </Grid>
          <Grid item xs={6}>
            <DashboardCard title="Children Pages" metric={this.state.totalChildrenPages} />
          </Grid>
          <Grid item xs={6}>
            <DashboardCard title="Adult Posts" metric={this.state.totalAdultPosts} />
          </Grid>
          <Grid item xs={6}>
            <DashboardCard title="Children Posts" metric={this.state.totalChildrenPosts} />
          </Grid>
          <Grid item xs={6}>
            <DashboardCard title="Adult Providers" metric={this.state.totalAdultProviders} />
          </Grid>
          <Grid item xs={6}>
            <DashboardCard title="Children Providers" metric={this.state.totalChildrenProviders} />
          </Grid>
          <Grid item xs={6}>
            <DashboardCard title="Adult Services" metric={this.state.totalAdultServices} />
          </Grid>
          <Grid item xs={6}>
            <DashboardCard title="Children Services" metric={this.state.totalChildrenServices} />
          </Grid>
          <Grid item xs={6}>
            <DashboardCard title="Children Library Articles" metric={this.state.totalChildrenArticles} />
          </Grid>
          <Grid item xs={12}>
            <DashboardCard title="Users" metric={this.state.totalUsers} />
          </Grid>
        </Grid>
      </Layout>
    );
  }
}
export default withSnackbar(Dashboard);
