import React from "react";
import { Router } from "@reach/router";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SnackbarProvider } from "notistack";

import PrivateRoute from "../components/app/PrivateRoute";

import Activate from "../components/app/Activate";

import Dashboard from "../components/app/Dashboard";

import Login from "../components/app/Login";
import Password from "../components/app/Password";
import Profile from "../components/app/Profile";
import ResetPassword from "../components/app/ResetPassword";

import AdultAnalytics from "../components/app/AdultAnalytics";
import AdultBoostSettings from "../components/app/AdultBoostSettings";
import AdultClinicalTrials from "../components/app/AdultClinicalTrials";
import AdultEvents from "../components/app/AdultEvents";
import AdultIndexSettings from "../components/app/AdultIndexSettings";
import AdultLocations from "../components/app/AdultLocations";
import AdultPages from "../components/app/AdultPages";
import AdultPosts from "../components/app/AdultPosts";
import AdultProviders from "../components/app/AdultProviders";
import AdultServices from "../components/app/AdultServices";

import ChildrenAnalytics from "../components/app/ChildrenAnalytics";
import ChildrenBoostSettings from "../components/app/ChildrenBoostSettings";
import ChildrenClinicalTrials from "../components/app/ChildrenClinicalTrials";
import ChildrenEvents from "../components/app/ChildrenEvents";
import ChildrenIndexSettings from "../components/app/ChildrenIndexSettings";
import ChildrenLocations from "../components/app/ChildrenLocations";
import ChildrenPages from "../components/app/ChildrenPages";
import ChildrenPosts from "../components/app/ChildrenPosts";
import ChildrenProviders from "../components/app/ChildrenProviders";
import ChildrenServices from "../components/app/ChildrenServices";
import ChildrenArticles from "../components/app/ChildrenArticles";

import Users from "../components/app/Users";
import InviteUser from "../components/app/InviteUser";
import EditUser from "../components/app/EditUser";

export default function Home() {
  const notistackRef = React.createRef();
  const onClickDismissSnackbar = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      ref={notistackRef}
      action={(key) => (
        <IconButton aria-label="close" onClick={onClickDismissSnackbar(key)}>
          <CloseIcon />
        </IconButton>
      )}
    >
      <Router>
        <PrivateRoute path="/app" component={Dashboard} />

        <PrivateRoute path="/app/analytics/adults" component={AdultAnalytics} />
        <PrivateRoute path="/app/boost-settings/adults" component={AdultBoostSettings} />
        <PrivateRoute path="/app/clinical-trials/adults" component={AdultClinicalTrials} />
        <PrivateRoute path="/app/events/adults" component={AdultEvents} />
        <PrivateRoute path="/app/index-settings/adults" component={AdultIndexSettings} />
        <PrivateRoute path="/app/locations/adults" component={AdultLocations} />
        <PrivateRoute path="/app/pages/adults" component={AdultPages} />
        <PrivateRoute path="/app/posts/adults" component={AdultPosts} />
        <PrivateRoute path="/app/providers/adults" component={AdultProviders} />
        <PrivateRoute path="/app/services/adults" component={AdultServices} />

        <PrivateRoute path="/app/analytics/children" component={ChildrenAnalytics} />
        <PrivateRoute path="/app/articles/children" component={ChildrenArticles} />
        <PrivateRoute path="/app/boost-settings/children" component={ChildrenBoostSettings} />
        <PrivateRoute path="/app/clinical-trials/children" component={ChildrenClinicalTrials} />
        <PrivateRoute path="/app/events/children" component={ChildrenEvents} />
        <PrivateRoute path="/app/index-settings/children" component={ChildrenIndexSettings} />
        <PrivateRoute path="/app/locations/children" component={ChildrenLocations} />
        <PrivateRoute path="/app/pages/children" component={ChildrenPages} />
        <PrivateRoute path="/app/posts/children" component={ChildrenPosts} />
        <PrivateRoute path="/app/providers/children" component={ChildrenProviders} />
        <PrivateRoute path="/app/services/children" component={ChildrenServices} />

        <PrivateRoute path="/app/users" component={Users} />
        <PrivateRoute path="/app/users/invite" component={InviteUser} />
        <PrivateRoute path="/app/users/:userId" component={EditUser} />
        <PrivateRoute path="/app/password" component={Password} />
        <PrivateRoute path="/app/profile" component={Profile} />

        <Activate path="/app/activate/:token" />
        <ResetPassword path="/app/reset/:token" />
        <Login path="/app/signin" />
      </Router>
    </SnackbarProvider>
  );
}
