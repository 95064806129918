import * as React from "react";
import axios from "axios";
import AuthToken from "../../helpers/AuthToken";
import { Typography } from "@mui/material";
import { withSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Layout from "../Layout";
import { signOut } from "../../helpers/LocalStorage";

class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: ``,
      password: ``,
      confirmPassword: ``,
    };
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.password !== this.state.confirmPassword) {
      this.props.enqueueSnackbar("Passwords don't match", { variant: "error" });
      return;
    }

    AuthToken();
    axios
      .put(`${process.env.GATSBY_API_URL}/password`, {
        currentPassword: this.state.currentPassword,
        newPassword: this.state.password,
      })
      .then(() => {
        this.setState({
          password: "",
          confirmPassword: "",
          currentPassword: "",
        });
        this.props.enqueueSnackbar("Updated password", { variant: "success" });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            this.props.enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          this.props.enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  };

  render() {
    return (
      <Layout>
        <Paper elevation={1} sx={{ p: 2 }}>
          <Typography align="center" component="h1" variant="h4">
            My Password
          </Typography>
          <Box sx={{ m: 2 }} />
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  type="password"
                  id="currentPassword"
                  label="Current Password"
                  name="currentPassword"
                  value={this.state.currentPassword}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  type="password"
                  id="password"
                  label="Password"
                  name="password"
                  value={this.state.password}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  type="password"
                  id="confirmPassword"
                  label="Confirm Password"
                  name="confirmPassword"
                  value={this.state.confirmPassword}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <Button type="submit" variant="contained" color="primary">
                  Update Password
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Layout>
    );
  }
}

export default withSnackbar(Password);
