export const roles = () => [
  { name: "Admin", value: "Admin" },
  { name: "Manager", value: "Manager" },
  { name: "Healthcare Editor", value: "Healthcare Editor" },
  { name: "Children Editor", value: "Children Editor" },
  { name: "User", value: "User" },
];

export const yesNo = () => [
  { name: "No", value: "No" },
  { name: "Yes", value: "Yes" },
];
