import axios from "axios";
import { getUser } from "./LocalStorage";

const AuthToken = () => {
  const user = getUser();
  const token = user.accessToken;
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export default AuthToken;
