import * as React from "react";
import axios from "axios";
import { withSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import MenuItem from "@mui/material/MenuItem";
import { navigate } from "gatsby";
import Layout from "../Layout";
import TitleNav from "../TitleNav";
import AuthToken from "../../helpers/AuthToken";
import { isBrowser, signOut } from "../../helpers/LocalStorage";

class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: ``,
      lastName: ``,
      email: ``,
      enabled: ``,
      role: ``,
      loading: false,
    };
  }

  componentDidMount() {
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/users/${this.props.userId}`)
      .then((response) => {
        var enabled = "No";
        if (response.data.enabled) {
          enabled = "Yes";
        }
        this.setState({
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          enabled: enabled,
          role: response.data.role,
        });
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          signOut();
        } else if (err.response.status === 404) {
          if (isBrowser()) {
            navigate("/app/users");
          }
        } else {
          this.props.enqueueSnackbar(err.response.data.reason, {
            variant: "error",
          });
        }
      });
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    var enabled = false;
    if (this.state.enabled === "Yes") {
      enabled = true;
    }

    const data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      enabled: enabled,
      role: this.state.role,
    };

    this.setState({ loading: true });
    AuthToken();
    axios
      .put(`${process.env.GATSBY_API_URL}/users/${this.props.userId}`, data)
      .then(() => {
        this.props.enqueueSnackbar("User details updated", {
          variant: "success",
        });
        if (isBrowser()) {
          navigate("/app/users");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response.status === 401 || err.response.status === 403) {
          signOut();
        } else {
          this.props.enqueueSnackbar(err.response.data.reason, {
            variant: "error",
          });
        }
      });
  };

  render() {
    const roles = [
      {
        name: "Admin",
        value: "Admin",
      },
      {
        name: "User",
        value: "User",
      },
    ];

    const yesNo = [
      { name: "No", value: "No" },
      { name: "Yes", value: "Yes" },
    ];
    return (
      <Layout>
        <Paper elevation={1} sx={{ p: 2 }}>
          <TitleNav title="Edit User Details" buttonLink="/app/users" buttonTitle="Users" />
          <Box sx={{ m: 2 }} />
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleUpdate}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <TextField
                  id="enabled"
                  select
                  required
                  label="Enabled"
                  name="enabled"
                  value={this.state.enabled}
                  onChange={this.handleUpdate}
                  variant="outlined"
                  fullWidth
                >
                  {yesNo.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="role"
                  select
                  required
                  label="Role"
                  name="role"
                  value={this.state.role}
                  onChange={this.handleUpdate}
                  variant="outlined"
                  fullWidth
                >
                  {roles.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <LoadingButton
                  fullWidth
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  loading={this.state.loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Layout>
    );
  }
}

export default withSnackbar(EditUser);
