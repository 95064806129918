import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";

const TitleNav = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Typography component="h1" variant="h4">
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography component="h1" variant="h4" align="right">
          <Button
            variant="outlined"
            startIcon={props.addIcon ? <AddIcon /> : <ArrowBackIcon />}
            href={props.buttonLink}
          >
            {props.buttonTitle}
          </Button>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TitleNav;
