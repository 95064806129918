import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "../../theme";
import axios from "axios";

import { isSignedIn } from "../../services/auth";
import { isBrowser } from "../../helpers/LocalStorage";
import { navigate } from "gatsby";
import { withSnackbar } from "notistack";
import Copyright from "../Copyright";

class ResetPassword extends React.Component {
  handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const password = data.get("password");
    const confirmPassword = data.get("confirmPassword");

    if (password.length < 8) {
      this.props.enqueueSnackbar("Password to short", {
        variant: "error",
      });
    } else if (password !== confirmPassword) {
      this.props.enqueueSnackbar("Passwords do not match", {
        variant: "error",
      });
    } else {
      axios
        .post(
          `${process.env.GATSBY_API_URL}/users/reset`,
          { password: password, token: this.props.token },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        )
        .then(() => {
          if (isBrowser()) {
            navigate("/");
          }
        })
        .catch(() => {
          this.props.enqueueSnackbar("Unable to reset password", {
            variant: "error",
          });
        });
    }
  };

  render() {
    if (isSignedIn()) {
      if (isBrowser()) {
        navigate(`/app`);
      }
    }

    return (
      <ThemeProvider theme={Theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src="/images/logo.png" alt="Norton Healthcare logo" />
            <Typography component="h1" variant="h5">
              Set New Password
            </Typography>
            <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Update Password
              </Button>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    );
  }
}

export default withSnackbar(ResetPassword);
