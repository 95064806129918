import * as React from "react";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import axios from "axios";
import { withSnackbar, useSnackbar } from "notistack";
import Layout from "../Layout";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ToolTip from "@mui/material/Tooltip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import moment from "moment";
import { navigate } from "gatsby";
import { isBrowser, getUser, signOut } from "../../helpers/LocalStorage";
import TitleNav from "../TitleNav";
import AuthToken from "../../helpers/AuthToken";

function Users() {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const deleteUser = React.useCallback(
    (id) => () => {
      AuthToken();
      axios
        .delete(`${process.env.GATSBY_API_URL}/users/${id}`)
        .then(() => {
          setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        })
        .catch((err) => {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        });
    },
    [enqueueSnackbar],
  );

  const editUser = React.useCallback(
    (id) => () => {
      const user = getUser();
      if (user.id === id) {
        enqueueSnackbar("You cannot edit yourself", { variant: "warning" });
      } else {
        if (isBrowser()) {
          navigate("/app/users/" + id);
        }
      }
    },
    [enqueueSnackbar],
  );

  const columns = React.useMemo(
    () => [
      { field: "id", headerName: "ID", hide: true },
      {
        field: "enabled",
        width: 100,
        type: "boolean",
        renderHeader: () => (
          <ToolTip title="Enabled">
            <CheckCircleIcon />
          </ToolTip>
        ),
      },
      {
        field: "activated",
        width: 100,
        type: "boolean",
        renderHeader: () => (
          <ToolTip title="Activated">
            <VerifiedUserIcon />
          </ToolTip>
        ),
      },
      { field: "firstName", headerName: "First Name", width: 200 },
      { field: "lastName", headerName: "Last Name", width: 200 },
      {
        field: "email",
        headerName: "Email Address",
        width: 200,
        renderCell: (params) => (
          <Button href={`mailto://${params.value}`} variant="text" size="small" style={{ textTransform: "lowercase" }}>
            {params.value}
          </Button>
        ),
      },
      { field: "role", headerName: "Role", width: 120 },
      {
        field: "createdAt",
        headerName: "Created",
        width: 225,
        type: "dateTime",
        hide: true,
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated",
        width: 225,
        type: "dateTime",
        hide: true,
        valueFormatter: (params) => {
          const valueFormatted = moment(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={editUser(params.id)} showInMenu />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={deleteUser(params.id)} showInMenu />,
        ],
      },
    ],
    [deleteUser, editUser],
  );

  React.useEffect(() => {
    setIsLoading(true);
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/users`)
      .then((response) => {
        setRows(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Unable to connect to the API", { variant: "error" });
        }
      });
  }, [enqueueSnackbar]);

  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <TitleNav title="Users" buttonLink="/app/users/invite" buttonTitle="User" addIcon />
        <Box sx={{ m: 1 }} />
        <div style={{ height: 575, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            loading={isLoading}
            disableColumnMenu
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div>
      </Paper>
    </Layout>
  );
}

export default withSnackbar(Users);
