import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "../../../theme";

function DashboardCard(props) {
  return (
    <ThemeProvider theme={Theme}>
      <Card>
        <CardContent>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h5" gutterBottom>
              {props.title}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {props.subTitle}
            </Typography>
            <Typography variant="h3" color="primary" gutterBottom>
              {props.metric}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}

export default DashboardCard;
