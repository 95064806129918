import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import axios from "axios";
import { withSnackbar, useSnackbar } from "notistack";
import Layout from "../Layout";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { signOut } from "../../helpers/LocalStorage";
import AuthToken from "../../helpers/AuthToken";

function AdultAnalytics() {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const [startDate, setStartDate] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const columns = React.useMemo(
    () => [
      { field: "term", width: 650, headerName: "Search Term" },
      { field: "count", width: 200, type: "number", headerName: "Total Searches" },
    ],
    [],
  );

  React.useEffect(() => {
    setIsLoading(true);
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/analytics/adults`)
      .then((response) => {
        setStartDate(moment.utc(response.data.startDate).format("LL"));
        setRows(response.data.analytics);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Unable to connect to search API", { variant: "error" });
        }
      });
  }, [enqueueSnackbar]);

  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Typography component="h1" variant="h4">
          Adult Search Terms as from {startDate}
        </Typography>
        <Box sx={{ m: 1 }} />
        <div style={{ height: 575, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            loading={isLoading}
            disableColumnMenu
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div>
      </Paper>
    </Layout>
  );
}

export default withSnackbar(AdultAnalytics);
