import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { withSnackbar, useSnackbar } from "notistack";
import Layout from "../Layout";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ToolTip from "@mui/material/Tooltip";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import Chip from "@mui/material/Chip";
import moment from "moment";
import { signOut } from "../../helpers/LocalStorage";
import AuthToken from "../../helpers/AuthToken";

function AdultPosts() {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100);

  const handleCellEditCommit = React.useCallback(
    async (params) => {
      AuthToken();
      const data = { [params.field]: params.value };
      axios
        .patch(`${process.env.GATSBY_API_URL}/posts/adults/${params.id}`, data)
        .then(() => {
          enqueueSnackbar("Adult post updated", { variant: "success" });
        })
        .catch((err) => {
          enqueueSnackbar(err.response.data.reason, { variant: "error" });
          setRows((prev) => [...prev]);
        });
    },
    [enqueueSnackbar],
  );

  const columns = React.useMemo(
    () => [
      { field: "id", headerName: "ID", hide: true },
      {
        field: "indexed",
        width: 100,
        type: "boolean",
        editable: true,
        renderHeader: () => (
          <ToolTip title="Indexed">
            <ManageSearchIcon />
          </ToolTip>
        ),
      },
      { field: "title", headerName: "Post", width: 450 },
      {
        field: "tags",
        headerName: "Tags",
        width: 350,
        editable: true,
        renderCell: (params) => {
          if (!params.value) {
            return "";
          } else if (params.value === "") {
            return "";
          } else {
            return (
              <div>
                {params.value.split(",").map((item, idx) => (
                  <Chip key={idx} label={item} color="primary" variant="outlined" sx={{ ml: 1 }} />
                ))}
              </div>
            );
          }
        },
      },
      {
        field: "createdAt",
        headerName: "Created",
        width: 225,
        type: "dateTime",
        hide: true,
        valueFormatter: (params) => {
          const valueFormatted = moment.utc(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated",
        width: 225,
        type: "dateTime",
        hide: true,
        valueFormatter: (params) => {
          const valueFormatted = moment.utc(params.value).format("llll");
          return `${valueFormatted}`;
        },
      },
    ],
    [],
  );

  React.useEffect(() => {
    setIsLoading(true);
    AuthToken();
    axios
      .get(`${process.env.GATSBY_API_URL}/posts/adults?page=${page}&per=${pageSize}`)
      .then((response) => {
        setPage(response.data.metadata.page);
        setPageSize(response.data.metadata.per);
        setTotal(response.data.metadata.total);
        setRows(response.data.items);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            signOut();
          } else {
            enqueueSnackbar(err.response.data.reason, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Unable to connect to search API", { variant: "error" });
        }
      });
  }, [enqueueSnackbar, page, pageSize]);

  return (
    <Layout>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Typography component="h1" variant="h4">
          Adult Posts
        </Typography>
        <Box sx={{ m: 1 }} />
        <div style={{ height: 575, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            loading={isLoading}
            disableColumnMenu
            paginationMode="server"
            page={page - 1}
            pageSize={pageSize}
            rowCount={total}
            onPageChange={(newPage) => setPage(newPage + 1)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{
              Toolbar: GridToolbar,
            }}
            onCellEditCommit={handleCellEditCommit}
          />
        </div>
      </Paper>
    </Layout>
  );
}

export default withSnackbar(AdultPosts);
