import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListIcon from "@mui/icons-material/List";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import Tooltip from "@mui/material/Tooltip";
import MedicalServicesRoundedIcon from "@mui/icons-material/MedicalServicesRounded";
import LocalHospitalRoundedIcon from "@mui/icons-material/LocalHospitalRounded";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import ArticleIcon from "@mui/icons-material/Article";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import EventIcon from "@mui/icons-material/Event";
import BarChartIcon from "@mui/icons-material/BarChart";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";

import { Link } from "gatsby";

export const mainListItems = (
  <ListItem button component={Link} to="/app">
    <Tooltip title="Dashboard" placement="left" arrow>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
    </Tooltip>
    <ListItemText primary="Dashboard" />
  </ListItem>
);

export const adultListItems = (
  <div>
    <ListSubheader component="div" inset>
      Adults
    </ListSubheader>
    <ListItem button component={Link} to="/app/clinical-trials/adults">
      <Tooltip title="Adult CLinical Trials" placement="left" arrow>
        <ListItemIcon>
          <VaccinesIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Clinical Trials" />
    </ListItem>
    <ListItem button component={Link} to="/app/events/adults">
      <Tooltip title="Adult Events" placement="left" arrow>
        <ListItemIcon>
          <EventIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Events" />
    </ListItem>
    <ListItem button component={Link} to="/app/locations/adults">
      <Tooltip title="Adult Locations" placement="left" arrow>
        <ListItemIcon>
          <LocalHospitalRoundedIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Locations" />
    </ListItem>
    <ListItem button component={Link} to="/app/pages/adults">
      <Tooltip title="Adult Pages" placement="left" arrow>
        <ListItemIcon>
          <ArticleIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Pages" />
    </ListItem>
    <ListItem button component={Link} to="/app/posts/adults">
      <Tooltip title="Adult Posts" placement="left" arrow>
        <ListItemIcon>
          <MenuBookIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Posts" />
    </ListItem>
    <ListItem button component={Link} to="/app/providers/adults">
      <Tooltip title="Adult Providers" placement="left" arrow>
        <ListItemIcon>
          <MedicalServicesRoundedIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Providers" />
    </ListItem>
    <ListItem button component={Link} to="/app/services/adults">
      <Tooltip title="Adult Services" placement="left" arrow>
        <ListItemIcon>
          <RoomServiceIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Services" />
    </ListItem>
    <ListItem button component={Link} to="/app/boost-settings/adults">
      <Tooltip title="Adult Boost Settings" placement="left" arrow>
        <ListItemIcon>
          <CloudUploadIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Boost Settings" />
    </ListItem>
    <ListItem button component={Link} to="/app/index-settings/adults">
      <Tooltip title="Adult Index Settings" placement="left" arrow>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Index Settings" />
    </ListItem>
    <ListItem button component={Link} to="/app/analytics/adults">
      <Tooltip title="Adult Searches" placement="left" arrow>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Search Terms" />
    </ListItem>
  </div>
);

export const childrenListItems = (
  <div>
    <ListSubheader component="div" inset>
      Children
    </ListSubheader>
    <ListItem button component={Link} to="/app/articles/children">
      <Tooltip title="Children Library Articles" placement="left" arrow>
        <ListItemIcon>
          <BookOutlinedIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Library Articles" />
    </ListItem>
    <ListItem button component={Link} to="/app/clinical-trials/children">
      <Tooltip title="Children Clinical Trials" placement="left" arrow>
        <ListItemIcon>
          <VaccinesIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Clinical Trials" />
    </ListItem>
    <ListItem button component={Link} to="/app/events/children">
      <Tooltip title="Children Events" placement="left" arrow>
        <ListItemIcon>
          <EventIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Events" />
    </ListItem>
    <ListItem button component={Link} to="/app/locations/children">
      <Tooltip title="Children Locations" placement="left" arrow>
        <ListItemIcon>
          <LocalHospitalRoundedIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Locations" />
    </ListItem>
    <ListItem button component={Link} to="/app/pages/children">
      <Tooltip title="Children Pages" placement="left" arrow>
        <ListItemIcon>
          <ArticleIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Pages" />
    </ListItem>
    <ListItem button component={Link} to="/app/posts/children">
      <Tooltip title="Children Posts" placement="left" arrow>
        <ListItemIcon>
          <MenuBookIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Posts" />
    </ListItem>
    <ListItem button component={Link} to="/app/providers/children">
      <Tooltip title="Children Providers" placement="left" arrow>
        <ListItemIcon>
          <MedicalServicesRoundedIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Providers" />
    </ListItem>
    <ListItem button component={Link} to="/app/services/children">
      <Tooltip title="Children Services" placement="left" arrow>
        <ListItemIcon>
          <RoomServiceIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Services" />
    </ListItem>
    <ListItem button component={Link} to="/app/boost-settings/children">
      <Tooltip title="Children Boost Settings" placement="left" arrow>
        <ListItemIcon>
          <CloudUploadIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Boost Settings" />
    </ListItem>
    <ListItem button component={Link} to="/app/index-settings/children">
      <Tooltip title="Children Index Settings" placement="left" arrow>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Index Settings" />
    </ListItem>
    <ListItem button component={Link} to="/app/analytics/children">
      <Tooltip title="Children Searches" placement="left" arrow>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Search Terms" />
    </ListItem>
  </div>
);

export const adminListItems = (
  <div>
    <ListItem button component={Link} to="/app/users">
      <Tooltip title="Users" placement="left" arrow>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary="Users" />
    </ListItem>
  </div>
);
